import { Locales } from "./locales"

import en from "./en.json"
import es from "./es.json"
import ru from "./ru.json"
import de from "./de.json"
import it from "./it.json"
import fr from "./fr.json"

export const messages: any = {
  [Locales.EN]: en,
  [Locales.ES]: es,
  [Locales.RU]: ru,
  [Locales.DE]: de,
  [Locales.IT]: it,
  [Locales.FR]: fr,

}

export const defaultLocale = Locales.EN
